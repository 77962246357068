var site = site || {};

site.translations = site.translations || {};
site.translations.elc_general = site.translations.elc_general || {};

(function ($, site) {
  Drupal.behaviors.carouselFormatterV1 = {
    attached: false,
    attach: function (context) {
      var self = this;

      if (self.attached) {
        return;
      }
      self.attached = true;
      var $formatter = $('.js-carousel-formatter-v1', context);

      $formatter.each(function () {
        var $thisFormatter = $(this);
        var $carousel = $thisFormatter.find('.js-carousel');
        var carouselDataset = $carousel.data() || {};
        var slickSlide = '.js-carousel__slide';
        var $arrowsDiv = $thisFormatter.find('.js-carousel-controls');
        var $dotsDiv = $thisFormatter.find('.js-carousel-dots');
        var nextLabelDefault =
          site.translations.elc_general && site.translations.elc_general.next
            ? site.translations.elc_general.next
            : 'next';
        var previousLabelDefault =
          site.translations.elc_general && site.translations.elc_general.previous
            ? site.translations.elc_general.previous
            : 'previous';
        var previousLabel = carouselDataset.previousLabel || previousLabelDefault;
        var arrowIcon = carouselDataset.arrowIcon || 'caret';
        var iconSuffix = carouselDataset.arrowIcon === 'caret' ? '_v2' : '';
        var nextLabel = carouselDataset.nextLabel || nextLabelDefault;
        var prevArrow =
          '<div class="slick-prev-button slick--custom"><svg role="img" aria-label="' +
          previousLabel +
          '" class="icon icon--' +
          arrowIcon +
          '--left' +
          iconSuffix +
          ' slick-prev"><use xlink:href="#' +
          arrowIcon +
          '--left' +
          iconSuffix +
          '"></use></svg></div>';
        var nextArrow =
          '<div class="slick-next-button slick--custom"><svg role="img" aria-label="' +
          nextLabel +
          '" class="icon icon--' +
          arrowIcon +
          '--right' +
          iconSuffix +
          ' slick-next"><use xlink:href="#' +
          arrowIcon +
          '--right' +
          iconSuffix +
          '"></use></svg></div>';
        var autoplay = carouselDataset.slidesAutoplay || false;
        var slidesToShow = carouselDataset.slidesToShow || 1;
        var slidesToScroll = carouselDataset.slidesToScroll || 1;
        var speed = carouselDataset.slidesSpeed || 300;
        var autoplaySpeed = carouselDataset.autoplaySpeed || 3000;
        var infinite = carouselDataset.infinite;
        var fade = carouselDataset.slidesFade ? true : false;
        var pcDotsArrowsArgs = self.checkDotsAndArrows(carouselDataset.dotsArrowsPc);
        var mobileDotsArrowsArgs = self.checkDotsAndArrows(carouselDataset.dotsArrowsMobile);
        var settingsResponsive = {
          appendArrows: $arrowsDiv,
          slide: slickSlide,
          fade: fade,
          speed: speed,
          slidesToShow: slidesToShow,
          slidesToScroll: slidesToScroll,
          autoplay: autoplay,
          infinite: infinite,
          cssEase: 'ease-in-out',
          dots: pcDotsArrowsArgs.dots,
          arrows: pcDotsArrowsArgs.arrows,
          appendDots: $dotsDiv,
          prevArrow: prevArrow,
          nextArrow: nextArrow,
          autoplaySpeed: autoplaySpeed,
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                dots: mobileDotsArrowsArgs.dots,
                arrows: mobileDotsArrowsArgs.arrows,
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
        };

        $carousel.not('.slick-initialized').slick(settingsResponsive);
      });
    },
    checkDotsAndArrows: function (dotsAndArrows) {
      var dotsArrowsArgs = {};

      switch (dotsAndArrows) {
        case 'arrows':
          dotsArrowsArgs.dots = false;
          dotsArrowsArgs.arrows = true;
          break;
        case 'dots':
          dotsArrowsArgs.dots = true;
          dotsArrowsArgs.arrows = false;
          break;
        case 'both':
          dotsArrowsArgs.dots = true;
          dotsArrowsArgs.arrows = true;
          break;
        case 'none':
          dotsArrowsArgs.dots = false;
          dotsArrowsArgs.arrows = false;
          break;
      }

      return dotsArrowsArgs;
    }
  };
})(jQuery, window.site || {});
